<style lang="less" scoped>
  .wrap {
    margin: auto;
    margin-top: 1.98vw; // 38px
    max-width: 1300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    .left {
      text-align: left;
      margin: 30px;
      max-width: 534px; // 534px / 27.8125vw
      >h1 {
        font-size: 26px;
        font-weight: bold;
        color: #222;
        display: flex;
        align-items: center;
        line-height: 26px;
        transform: translateX(200px);
        opacity: 0;
        transition: .8s;
      }
      >h1::before {
        content: '';
        width: 5px;
        height: 26px;
        display: inline-block;
        margin-right: 10px;
      }
      >span {
        font-size:  16px;
        color: #999;
        line-height: 30px;
        display: block;
        transform: translateX(200px);
        opacity: 0;
        transition: 1s;
      }
    }
    .right {
      width: 640px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      transform: translateX(-200px);
      opacity: 0;
      transition: .8s;
      .logo-wrap {
        min-width: 154px;
        min-height: 204px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        >img {
          width: 82px;
          height: 82px;
          object-fit: contain;
          transition: .3s;
          overflow: hidden;
        }
        pre {
          margin: 0;
          font-family: none;
          font-size: 14px;
          color: #333;
          position: absolute;
          top: 80%;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          overflow: hidden;
        }
        >object {
          width: 0;
          opacity: 0;
          display: flex;
          transition: .8s;
          overflow: hidden;
          position: relative;
          .icon-wrap {
            width: 80px;
            height: 204px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            >img {
              width: 64px;
              height: 44px;
              object-fit: contain;
              transition: .3s;
            }
            >pre {
              top: 65%;
            }
          }
          .icon-wrap:hover {
            >img {
              transform: scale(1.2);
            }
            >pre {
              color: #0167D8;
            }
          }
        }
      }
      .logo-wrap:hover {
        >img {
          transform: scale(1.1);
        }
        >pre {
          color: #0167D8;
        }
      }
      .logo-wrap-hover:hover {
        >img,>pre {
          width: 0;
          opacity: 0;
        }
        >object {
          opacity: 1;
        }
        .w1 {
          width: 80px;
        }
        .w2 {
          width: 160px;
        }
        .w3 {
          width: 240px;
        }
        .w4 {
          width: 320px;
        }
        .w5 {
          width: 400px;
        }
        .w6 {
          width: 480px;
        }
      }
    }
  }
</style>
<style lang="less">
  .customImgBox {
    width: auto !important;
    img {
      width: 150px;
    }
  }
</style>

<template>
  <div class="wrap">
    <div class="left">
      <h1 class="transition">{{projectItem.title}}</h1>
      <span class="transition">{{projectItem.content}}</span>
    </div>
    <div class="right transition">
      <div class="logo-wrap" :class="{'logo-wrap-hover': !logoItem.url}" @click="toUrl(logoItem)" v-for="(logoItem,logoIndex) in projectItem.logoList" :key="logoIndex">
        <img :src="logoItem.img" alt="">
        <pre>{{logoItem.name}}</pre>
        <object :class="'w'+logoItem.iconList.length" v-if="!logoItem.url">
          <div class="icon-wrap" @click.stop="toUrl(iconItem)" v-for="(iconItem,iconIndex) in logoItem.iconList" :key="iconIndex">
            <img :src="iconItem.img" alt="">
            <pre>{{iconItem.name}}</pre>
          </div>
        </object>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { langStore } from '@/store/lang'
export default {
  props: ['projectItem'],
  data() {
    return {
      lang: langStore(),
    }
  },
  methods: {
    toUrl(item) {
      if (item.url) {
        window.open(item.url)
      } else if (item.code) {
        this.$notify({
          title: item.name,
          customClass: 'customImgBox', 
          dangerouslyUseHTMLString: true,
          message: `<img src="${item.code}">`,
          duration: 0,
        })
      } else {
        this.$notify({
          title: storeToRefs(langStore()).link,
          type: 'warning'
        })
      }
    }
  }
}
</script>
