export default {
  link: 'No link',
  title: 'Basic ability',
  navList:['Basic ability','Industry + cloud','Operational tool','Cooperation scenic spot'],
  wrapList: [
    {
      title: 'Basic ability',
      projectList: [
        // 数据分析
        {
          style: 's1',
          title: 'Data and Analysis',
          content: 'It provides a comprehensive intelligent platform for database query, large-scale calculation, data reports and other services to improve the efficiency of customer data analysis.',
          logoList: [
            // 灵狐
            {
              name: 'DataFox',
              img: require('@/assets/logo/灵狐.png'),
              url: 'https://datafox.shukeyun.com/#/',
              iconList: [],
            },
            // 分析引擎
            {
              name: 'Big data\nanalysis engine',
              img: require('@/assets/logo/分析引擎.png'),
              url: 'https://redash-prod.shukeyun.com/',
              iconList: [],
            },
          ]
        },
        // 支付安全
        {
          style: 's2',
          title: 'Payment and Security',
          content: 'Combined with blockchain technology, ensure the security of transaction settlement, and effectively integrate the fund settlement of scenic spots and tourism-related enterprises.',
          logoList: [
            // 智旅链
            {
              name: 'Wisdom brigade chain',
              img: require('@/assets/logo/智旅链.png'),
              url: '',
              iconList: [
                {
                  name: 'Blockchain\nservice\nplatform',
                  img: require('@/assets/icon/区块链服务平台.png'),
                  url: 'https://prod.shukeyun.com/blockchain/homepage/#',
                },
                {
                  name: 'Data\nregister',
                  img: require('@/assets/icon/数据存证.png'),
                  url: 'https://prod.shukeyun.com/blockchain/evidence-ui/#/HomeIndex',
                },
                {
                  name: 'BDS\nblockchain\nbrowser',
                  img: require('@/assets/icon/BDS 区块链浏览器.png'),
                  url: 'https://explorer.zhilvlian.com',
                },
                {
                  name: 'BDS\nNFT\nbrowser',
                  img: require('@/assets/icon/BDS NFT 浏览器.png'),
                  url: 'https://nft.zhilvlian.com/#/DigitalCollection',
                },
                {
                  name: 'Digital\ncollection\nH5',
                  img: require('@/assets/icon/数字藏品 H5.png'),
                  url: 'https://nft.zhilvlian.com/blockchain/h5/#/',
                },
                {
                  name: 'Digital\ncollection\nbackend',
                  img: require('@/assets/icon/数字藏品管理后台.png'),
                  url: 'https://nft.zhilvlian.com/blockchain/management/#/',
                },
              ],
            },
            // 云联网络
            {
              name: 'Cloud network',
              img: require('@/assets/logo/云联网络.png'),
              url: 'https://www.upaypal.com',
              iconList: [],
            },
          ]
        },
      ]
    },
    {
      title: 'Industry + cloud',
      projectList: [
        // 生态监管
        {
          style: 's3',
          title: 'Ecology and Regulation',
          content: 'Satellite communications, big data and other technologies should be integrated to build an all-dimensional and multi-tiered ecological monitoring platform and improve emergency response efficiency.',
          logoList: [
            // 监管
            {
              name: 'Smart tourism\nsupervision platform',
              img: require('@/assets/logo/朝天旅游.png'),
              url: 'https://prod.shukeyun.com/data/dashboard/#/?id=zly_01_01',
              iconList: [],
            },
            // 监管
            {
              name: 'Ecological monitoring\ndisaster warning\nmanagement platform',
              img: require('@/assets/logo/生态监测.png'),
              url: 'http://yanjinghe.zhlyjg.cn',
              iconList: [],
            },
            // 森林防火
            {
              name: 'Forest fire\nmonitoring warning\ncommand system',
              img: require('@/assets/logo/森林防火.png'),
              url: '',
              iconList: [
                {
                  name: 'Data\nscreen',
                  img: require('@/assets/icon/数据大屏.png'),
                  url: 'https://slfh.shengtaiyun.com/',
                },
                {
                  name: 'Backend',
                  img: require('@/assets/icon/管理后台.png'),
                  url: 'https://slfh.shengtaiyun.com/management',
                },
                {
                  name: 'APP\ndownload',
                  img: require('@/assets/qrCode/森林防火.png'),
                  code: require('@/assets/qrCode/森林防火.png'),
                  url: '',
                },
              ],
            },
          ]
        },
        // 城市管理
        {
          style: 's4',
          title: 'Cities and Management',
          content: 'Based on the omni-domain digital base, it uses the digital-real integration engine to provide more convenient and fast management and bring users a more comfortable "digital" life experience.',
          logoList: [
            // 车无忌
            {
              name: 'Car Wuji',
              img: require('@/assets/logo/车无忌.png'),
              url: '',
              iconList: [
                {
                  name: 'Backend',
                  img: require('@/assets/icon/管理后台_.png'),
                  url: 'http://www.parkingfree.cn/o2o-admin',
                },
                {
                  name: 'Property\nbackground',
                  img: require('@/assets/icon/物业后台.png'),
                  url: 'http://www.parkingfree.cn/o2o-cp',
                }
              ],
            },
            // 智慧社区
            {
              name: 'Smart\ncommunity\nsystem',
              img: require('@/assets/logo/印象石灰.png'),
              url: 'https://www.shgzsq.com/',
              iconList: [],
            },
            // 母婴室
            {
              name: 'Smart\nmother and baby\nroom',
              img: require('@/assets/logo/智慧母婴室.png'),
              url: '',
              iconList: [
                {
                  name: 'Operations\ncenter',
                  img: require('@/assets/icon/运营中心.png'),
                  url: 'http://mng.quanzilx.com',
                },
                {
                  name: 'Supervision\ncenter',
                  img: require('@/assets/icon/监管中心.png'),
                  url: 'http://jgf.quanzilx.com',
                },
                {
                  name: 'Backend\ncenter',
                  img: require('@/assets/icon/管理中心.png'),
                  url: 'http://my.quanzilx.com',
                },
                {
                  name: 'Map\nof mother\nand maby\nroom',
                  img: require('@/assets/qrCode/母婴室.png'),
                  code: require('@/assets/qrCode/母婴室.png'),
                  url: '',
                }
              ],
            },
          ]
        },
        // 文化旅游
        {
          style: 's5',
          title: 'Culture and Tourism',
          content: 'Will go deep into the tourism industry, provide solutions for the digital construction and operation of tourism destinations, and build a cloud service platform for the whole scene destinations.',
          logoList: [
            // 途途
            {
              name: 'TUTU',
              img: require('@/assets/logo/途途.png'),
              url: '',
              iconList: [
                {
                  name: 'Tutubanyou',
                  img: require('@/assets/qrCode/途途.png'),
                  code: require('@/assets/qrCode/途途.png'),
                  url: '',
                },
                {
                  name: 'Counting\nhomo\nsapiens\nTUTU',
                  img: require('@/assets/icon/tutu.png'),
                  url: 'https://prod.shukeyun.com/meta/chat/',
                },
                {
                  name: 'Backend',
                  img: require('@/assets/icon/物业后台.png'),
                  url: 'https://meta.zhilvlian.com/management/welcome',
                }
              ],
            },
            // 智慧文旅
            {
              name: 'Wisdom cultural tourism',
              img: require('@/assets/logo/智慧文旅.png'),
              url: 'https://hly.net/#/',
              iconList: [
                // // 慧旅云
                // {
                //   name: '',
                //   img: require('@/assets/logo/慧旅云.png'),
                //   url: 'https://prod.shukeyun.com/cas/login/#/login?appId=xZZLVvBpD79TQVxPWOnd',
                // },
                // // 慧景云
                // {
                //   name: '',
                //   img: require('@/assets/logo/慧景云.png'),
                //   url: 'https://prod.shukeyun.com/cas/login/#/login?appId=QMMS89YvTZ0Qs5sRWXFL&tk=null&scenicCode=yndl',
                // },
                // // 易旅通
                // {
                //   name: '',
                //   img: require('@/assets/logo/易旅通.png'),
                //   url: 'https://prod.shukeyun.com/cas/login/#/login?appId=yc3fvV45yEWablblgUoq',
                // },
                // // 易旅宝
                // {
                //   name: 'Yilvbao',
                //   img: require('@/assets/qrCode/易旅宝.png'),
                //   code: require('@/assets/qrCode/易旅宝.png'),
                //   url: '',
                // },
              ]
            },
            // 检票平台
            {
              name: 'Check-in platform',
              img: require('@/assets/logo/检票平台.png'),
              url: '',
              iconList: [
                {
                  name: 'Check-in\nplatform',
                  img: require('@/assets/qrCode/检票.png'),
                  code: require('@/assets/qrCode/检票.png'),
                  url: '',
                }
              ],
            },
          ]
        }
      ]
    },
  ],
  scenicList: [
    {
      name: 'Zengjiashan',
      img: require('@/assets/scenic/jq_04.png'),
      url: 'http://zjs.yeahtour.cn'
    },
    {
      name: 'Mingyuexia',
      img: require('@/assets/scenic/jq_03.png'),
      url: 'http://myx.yeahtour.cn'
    },
    {
      name: 'Huangze temple',
      img: require('@/assets/scenic/jq_05.png'),
      url: 'http://hzs.yeahtour.cn'
    },
    {
      name: 'Jianmenguan',
      img: require('@/assets/scenic/jq_09.png'),
      url: 'http://jmg.yeahtour.cn'
    },
    {
      name: 'Thousand-buddha cliff',
      img: require('@/assets/scenic/jq_10.png'),
      url: 'http://qfy.yeahtour.cn'
    },
    {
      name: 'Longmen pavilion',
      img: require('@/assets/scenic/jq_01.png'),
      url: 'http://lmg.yeahtour.cn'
    },
    {
      name: 'Shuimogou',
      img: require('@/assets/scenic/jq_02.png'),
      url: 'http://smg.yeahtour.cn'
    },
    {
      name: 'Donghekou',
      img: require('@/assets/scenic/jq_06.png'),
      url: 'http://dhk.yeahtour.cn'
    },
    {
      name: 'Tangjia river',
      img: require('@/assets/scenic/jq_07.png'),
      url: 'http://tjh.yeahtour.cn'
    },
    {
      name: 'Pear expo garden',
      img: require('@/assets/scenic/jq_08.png'),
      url: 'http://lby.yeahtour.cn'
    },
    {
      name: 'Micangshan grand canyon',
      img: require('@/assets/scenic/jq_12.png'),
      url: 'http://dxg.yeahtour.cn'
    },
    {
      name: 'Guchengshan',
      img: require('@/assets/scenic/jq_13.png'),
      url: 'http://mcs.yeahtour.cn'
    },
    {
      name: 'Carl city',
      img: require('@/assets/scenic/jq_11.png'),
      url: 'http://kec.yeahtour.cn'
    },
    {
      name: 'Wangcang county cultural center',
      img: require('@/assets/scenic/jq_14.png'),
      url: 'http://wcwhg.yeahtour.cn'
    },
    {
      name: 'Wangcang county library',
      img: require('@/assets/scenic/jq_15.png'),
      url: 'http://wctsg.yeahtour.cn'
    },
    {
      name: 'Cangxi county cultural center',
      img: require('@/assets/scenic/jq_16.png'),
      url: 'http://cxwhg.yeahtour.cn'
    },
    {
      name: 'Cangxi county library',
      img: require('@/assets/scenic/jq_17.png'),
      url: 'http://cxtsg.yeahtour.cn'
    },
    {
      name: 'Jiangue county library',
      img: require('@/assets/scenic/jq_18.png'),
      url: 'http://jgtsg.yeahtour.cn'
    },
    {
      name: 'Jingzhou garden expo garden',
      img: require('@/assets/scenic/jq_19.png'),
      url: 'http://www.jzyby.com'
    }
  ],
  toolsList: [
    {
      img: require('@/assets/tools/01.png'),
      url: 'http://10.8.13.68'
    },
    {
      img: require('@/assets/tools/02.png'),
      url: 'https://10.8.1.221:31199'
    },
    {
      img: require('@/assets/tools/03.png'),
      url: 'http://git.shukeyun.com'
    },
    {
      img: require('@/assets/tools/04.png'),
      url: 'http://prod-grafana.shukeyun.com'
    },
    {
      img: require('@/assets/tools/05.png'),
      url: 'http://grafana-prod.shukeyun.com'
    },
    {
      img: require('@/assets/tools/06.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/07.png'),
      url: 'http://10.8.13.13/zabbix/'
    },
    {
      img: require('@/assets/tools/08.png'),
      url: 'http://rancher.shukeyun.com'
    },
    {
      img: require('@/assets/tools/09.png'),
      url: 'https://10.8.11.60:8443'
    },
    {
      img: require('@/assets/tools/10.png'),
      url: 'http://minio-prod.shukeyun.com'
    },
    {
      img: require('@/assets/tools/11.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/12.png'),
      url: 'http://reg.shukeyun.com'
    },
    {
      img: require('@/assets/tools/13.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/14.png'),
      url: 'http://10.8.11.55:8080/'
    },
    {
      img: require('@/assets/tools/15.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/16.png'),
      url: 'http://grafana-prod.shukeyun.com'
    },
    {
      img: require('@/assets/tools/17.png'),
      url: 'https://redash-prod.shukeyun.com'
    },
    {
      img: require('@/assets/tools/18.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/19.png'),
      url: 'http://192.168.90.143:8069/'
    },
    {
      img: require('@/assets/tools/20.png'),
      url: ''
    }
  ],
  footer: {
    company: '「Global Digetal Technology Group CO.,Ltd.」',
    copyright: 'All rights reserved',
    tips: 'Account'
  }
}