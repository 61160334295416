export default {
  link: '暂无链接',
  navList: ['基础能力','行业 + 云','运维工具','合作景区'],
  wrapList: [
    {
      title: '基础能力',
      projectList: [
        // 数据分析
        {
          style: 's1',
          title: '数据与分析',
          content: '提供数据库查询、大规模计算、数据报表等服务的综合智能平台，提高客户数据分析效率。',
          logoList: [
            // 灵狐
            {
              name: 'DataFox',
              img: require('@/assets/logo/灵狐.png'),
              url: 'https://datafox.shukeyun.com/#/',
              iconList: [],
            },
            // 分析引擎
            {
              name: '大数据分析引擎',
              img: require('@/assets/logo/分析引擎.png'),
              url: 'https://redash-prod.shukeyun.com/',
              iconList: [],
            },
          ]
        },
        // 支付安全
        {
          style: 's2',
          title: '支付与安全',
          content: '结合区块链技术，保证交易结算的安全性，有效整合景区和涉旅企业的资金结算问题。',
          logoList: [
            // 智旅链
            {
              name: '智旅链',
              img: require('@/assets/logo/智旅链.png'),
              url: '',
              iconList: [
                {
                  name: '区块链\n服务平台',
                  img: require('@/assets/icon/区块链服务平台.png'),
                  url: 'https://prod.shukeyun.com/blockchain/homepage/#',
                },
                {
                  name: '数据存证',
                  img: require('@/assets/icon/数据存证.png'),
                  url: 'https://prod.shukeyun.com/blockchain/evidence-ui/#/HomeIndex',
                },
                {
                  name: 'BDS 区块链\n浏览器',
                  img: require('@/assets/icon/BDS 区块链浏览器.png'),
                  url: 'https://explorer.zhilvlian.com',
                },
                {
                  name: 'BDS NFT\n浏览器',
                  img: require('@/assets/icon/BDS NFT 浏览器.png'),
                  url: 'https://nft.zhilvlian.com/#/DigitalCollection',
                },
                {
                  name: '数字藏品\nH5',
                  img: require('@/assets/icon/数字藏品 H5.png'),
                  url: 'https://nft.zhilvlian.com/blockchain/h5/#/',
                },
                {
                  name: '数字藏品\n管理后台',
                  img: require('@/assets/icon/数字藏品管理后台.png'),
                  url: 'https://nft.zhilvlian.com/blockchain/management/#/',
                },
              ],
            },
            // 云联网络
            {
              name: '云联网络',
              img: require('@/assets/logo/云联网络.png'),
              url: 'https://www.upaypal.com',
              iconList: [],
            },
          ]
        },
      ]
    },
    {
      title: '行业 + 云',
      projectList: [
        // 生态监管
        {
          style: 's3',
          title: '生态与监管',
          content: '融合卫星通信、大数据等技术，构建全方位、多层次的生态监测平台，提高应急处置效率。',
          logoList: [
            // 监管
            {
              name: '智慧旅游监管平台',
              img: require('@/assets/logo/朝天旅游.png'),
              url: 'https://prod.shukeyun.com/data/dashboard/#/?id=zly_01_01',
              iconList: [],
            },
            // 监管
            {
              name: '生态监测与灾害预警\n管理平台',
              img: require('@/assets/logo/生态监测.png'),
              url: 'http://yanjinghe.zhlyjg.cn',
              iconList: [],
            },
            // 森林防火
            {
              name: '森林防火监测预警和\n指挥系统',
              img: require('@/assets/logo/森林防火.png'),
              url: '',
              iconList: [
                {
                  name: '数据大屏',
                  img: require('@/assets/icon/数据大屏.png'),
                  url: 'https://slfh.shengtaiyun.com/',
                },
                {
                  name: '管理后台',
                  img: require('@/assets/icon/管理后台.png'),
                  url: 'https://slfh.shengtaiyun.com/management',
                },
                {
                  name: 'APP 下载',
                  img: require('@/assets/qrCode/森林防火.png'),
                  code: require('@/assets/qrCode/森林防火.png'),
                  url: '',
                },
              ],
            },
          ]
        },
        // 城市管理
        {
          style: 's4',
          title: '城市与管理',
          content: '基于全域数字底座，利用数实融合引擎，提供更方便快捷的管理，给用户带来更加舒适的“数字化”生活体验。',
          logoList: [
            // 车无忌
            {
              name: '车无忌',
              img: require('@/assets/logo/车无忌.png'),
              url: '',
              iconList: [
                {
                  name: '管理后台',
                  img: require('@/assets/icon/管理后台_.png'),
                  url: 'http://www.parkingfree.cn/o2o-admin',
                },
                {
                  name: '物业后台',
                  img: require('@/assets/icon/物业后台.png'),
                  url: 'http://www.parkingfree.cn/o2o-cp',
                }
              ],
            },
            // 智慧社区
            {
              name: '智慧社区系统',
              img: require('@/assets/logo/印象石灰.png'),
              url: 'https://www.shgzsq.com/',
              iconList: [],
            },
            // 母婴室
            {
              name: '智慧母婴室',
              img: require('@/assets/logo/智慧母婴室.png'),
              url: '',
              iconList: [
                {
                  name: '运营中心',
                  img: require('@/assets/icon/运营中心.png'),
                  url: 'http://mng.quanzilx.com',
                },
                {
                  name: '监管中心',
                  img: require('@/assets/icon/监管中心.png'),
                  url: 'http://jgf.quanzilx.com',
                },
                {
                  name: '管理中心',
                  img: require('@/assets/icon/管理中心.png'),
                  url: 'http://my.quanzilx.com',
                },
                {
                  name: '母婴室地图',
                  img: require('@/assets/qrCode/母婴室.png'),
                  code: require('@/assets/qrCode/母婴室.png'),
                  url: '',
                }
              ],
            },
          ]
        },
        // 文化旅游
        {
          style: 's5',
          title: '文化与旅游',
          content: '深入旅游行业，提供旅游目的地数字化建设与运营的解决方案，打造全场景目的地云服务平台。',
          logoList: [
            // 途途
            {
              name: 'TUTU',
              img: require('@/assets/logo/途途.png'),
              url: '',
              iconList: [
                {
                  name: '途途伴游',
                  img: require('@/assets/qrCode/途途.png'),
                  code: require('@/assets/qrCode/途途.png'),
                  url: '',
                },
                {
                  name: '数智人 TUTU',
                  img: require('@/assets/icon/tutu.png'),
                  url: 'https://prod.shukeyun.com/meta/chat/',
                },
                {
                  name: '后台',
                  img: require('@/assets/icon/物业后台.png'),
                  url: 'https://meta.zhilvlian.com/management/welcome',
                }
              ],
            },
            // 智慧文旅
            {
              name: '智慧文旅',
              img: require('@/assets/logo/智慧文旅.png'),
              url: 'https://hly.net/#/',
              iconList: [
                // // 慧旅云
                // {
                //   name: '',
                //   img: require('@/assets/logo/慧旅云.png'),
                //   url: 'https://prod.shukeyun.com/cas/login/#/login?appId=xZZLVvBpD79TQVxPWOnd',
                // },
                // // 慧景云
                // {
                //   name: '',
                //   img: require('@/assets/logo/慧景云.png'),
                //   url: 'https://prod.shukeyun.com/cas/login/#/login?appId=QMMS89YvTZ0Qs5sRWXFL&tk=null&scenicCode=yndl',
                // },
                // // 易旅通
                // {
                //   name: '',
                //   img: require('@/assets/logo/易旅通.png'),
                //   url: 'https://prod.shukeyun.com/cas/login/#/login?appId=yc3fvV45yEWablblgUoq',
                // },
                // // 易旅宝
                // {
                //   name: '易旅宝',
                //   img: require('@/assets/qrCode/易旅宝.png'),
                //   code: require('@/assets/qrCode/易旅宝.png'),
                //   url: '',
                // },
              ]
            },
            // 检票平台
            {
              name: '检票平台',
              img: require('@/assets/logo/检票平台.png'),
              url: '',
              iconList: [
                {
                  name: '检票平台',
                  img: require('@/assets/qrCode/检票.png'),
                  code: require('@/assets/qrCode/检票.png'),
                  url: '',
                }
              ],
            },
          ]
        }
      ]
    },
  ],
  scenicList: [
    {
      name: '曾家山',
      img: require('@/assets/scenic/jq_04.png'),
      url: 'http://zjs.yeahtour.cn'
    },
    {
      name: '明月峡',
      img: require('@/assets/scenic/jq_03.png'),
      url: 'http://myx.yeahtour.cn'
    },
    {
      name: '皇泽寺',
      img: require('@/assets/scenic/jq_05.png'),
      url: 'http://hzs.yeahtour.cn'
    },
    {
      name: '剑门关',
      img: require('@/assets/scenic/jq_09.png'),
      url: 'http://jmg.yeahtour.cn'
    },
    {
      name: '千佛崖',
      img: require('@/assets/scenic/jq_10.png'),
      url: 'http://qfy.yeahtour.cn'
    },
    {
      name: '龙门阁',
      img: require('@/assets/scenic/jq_01.png'),
      url: 'http://lmg.yeahtour.cn'
    },
    {
      name: '水磨沟',
      img: require('@/assets/scenic/jq_02.png'),
      url: 'http://smg.yeahtour.cn'
    },
    {
      name: '东河口',
      img: require('@/assets/scenic/jq_06.png'),
      url: 'http://dhk.yeahtour.cn'
    },
    {
      name: '唐家河',
      img: require('@/assets/scenic/jq_07.png'),
      url: 'http://tjh.yeahtour.cn'
    },
    {
      name: '梨博园',
      img: require('@/assets/scenic/jq_08.png'),
      url: 'http://lby.yeahtour.cn'
    },
    {
      name: '米仓山大峡谷',
      img: require('@/assets/scenic/jq_12.png'),
      url: 'http://dxg.yeahtour.cn'
    },
    {
      name: '鼓城山',
      img: require('@/assets/scenic/jq_13.png'),
      url: 'http://mcs.yeahtour.cn'
    },
    {
      name: '卡尔城',
      img: require('@/assets/scenic/jq_11.png'),
      url: 'http://kec.yeahtour.cn'
    },
    {
      name: '旺苍县文化馆',
      img: require('@/assets/scenic/jq_14.png'),
      url: 'http://wcwhg.yeahtour.cn'
    },
    {
      name: '旺苍县图书馆',
      img: require('@/assets/scenic/jq_15.png'),
      url: 'http://wctsg.yeahtour.cn'
    },
    {
      name: '苍溪县文化馆',
      img: require('@/assets/scenic/jq_16.png'),
      url: 'http://cxwhg.yeahtour.cn'
    },
    {
      name: '苍溪县图书馆',
      img: require('@/assets/scenic/jq_17.png'),
      url: 'http://cxtsg.yeahtour.cn'
    },
    {
      name: '剑阁县图书馆',
      img: require('@/assets/scenic/jq_18.png'),
      url: 'http://jgtsg.yeahtour.cn'
    },
    {
      name: '荆州园博园',
      img: require('@/assets/scenic/jq_19.png'),
      url: 'http://www.jzyby.com'
    }
  ],
  toolsList: [
    {
      img: require('@/assets/tools/01.png'),
      url: 'http://10.8.13.68'
    },
    {
      img: require('@/assets/tools/02.png'),
      url: 'https://10.8.1.221:31199'
    },
    {
      img: require('@/assets/tools/03.png'),
      url: 'http://git.shukeyun.com'
    },
    {
      img: require('@/assets/tools/04.png'),
      url: 'http://prod-grafana.shukeyun.com'
    },
    {
      img: require('@/assets/tools/05.png'),
      url: 'http://grafana-prod.shukeyun.com'
    },
    {
      img: require('@/assets/tools/06.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/07.png'),
      url: 'http://10.8.13.13/zabbix/'
    },
    {
      img: require('@/assets/tools/08.png'),
      url: 'http://rancher.shukeyun.com'
    },
    {
      img: require('@/assets/tools/09.png'),
      url: 'https://10.8.11.60:8443'
    },
    {
      img: require('@/assets/tools/10.png'),
      url: 'http://minio-prod.shukeyun.com'
    },
    {
      img: require('@/assets/tools/11.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/12.png'),
      url: 'http://reg.shukeyun.com'
    },
    {
      img: require('@/assets/tools/13.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/14.png'),
      url: 'http://10.8.11.55:8080/'
    },
    {
      img: require('@/assets/tools/15.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/16.png'),
      url: 'http://grafana-prod.shukeyun.com'
    },
    {
      img: require('@/assets/tools/17.png'),
      url: 'https://redash-prod.shukeyun.com'
    },
    {
      img: require('@/assets/tools/18.png'),
      url: ''
    },
    {
      img: require('@/assets/tools/19.png'),
      url: 'http://192.168.90.143:8069/'
    },
    {
      img: require('@/assets/tools/20.png'),
      url: ''
    }
  ],
  footer: {
    company: '「环球数科集团」',
    copyright: '版权所有',
    tips: '账号'
  }
}